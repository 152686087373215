<template>
  <div>
    <IxCollapsePanel v-model="showCompanyFieldStatistics" border class="statistics-collapse-panel">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CompanyFieldStatistics</IxRes>
      </template>
      <div class="statistics">
        <FieldOverviewStatistics :org-unit-id="orgUnitId" :harvest-year="harvestYear" />
      </div>
    </IxCollapsePanel>
    <IxCollapsePanel
      v-if="$can('Use.Frs.Cultivations')"
      v-model="showCompanyCultivationStatistics"
      border
      class="statistics-collapse-panel"
    >
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CompanyCultivationStatistics</IxRes>
      </template>
      <template #additional-info>
        <IxButton @click="$router.push({name: 'cultivations'})">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationPlan</IxRes>
        </IxButton>
      </template>
      <div class="statistics">
        <CultivationStatistics :org-unit-id="orgUnitId" :harvest-year="harvestYear" />
      </div>
    </IxCollapsePanel>
    <IxCollapsePanel v-model="showCompanySoilStatistics" border class="statistics-collapse-panel">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CompanySoilStatistics</IxRes>
      </template>
      <div class="statistics">
        <SoilOverviewStatistics :org-unit-id="orgUnitId" :harvest-year="harvestYear" />
      </div>
    </IxCollapsePanel>
    <IxCollapsePanel v-model="showCompanySoilQualityStatistics" border class="statistics-collapse-panel">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CompanySoilConditionStatistics</IxRes>
      </template>
      <div class="statistics">
        <SoilConditionOverviewStatistics :org-unit-id="orgUnitId" :harvest-year="harvestYear" />
      </div>
    </IxCollapsePanel>
  </div>
</template>

<script>
import {mapUiFlags} from '@frs/helpers/ui'

import IxButton from '@components/IxButton'
import IxCollapsePanel from '@components/ix/IxCollapsePanel'

import CultivationStatistics from '@frs/components/cultivation-overview/summary/CultivationStatistics'
import FieldOverviewStatistics from './FieldOverviewStatistics'
import SoilOverviewStatistics from './SoilOverviewStatistics'
import SoilConditionOverviewStatistics from './SoilConditionOverviewStatistics'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    IxButton,
    IxCollapsePanel,
    CultivationStatistics,
    FieldOverviewStatistics,
    SoilOverviewStatistics,
    SoilConditionOverviewStatistics
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    orgUnitId: {
      type: String,
      required: true
    },
    harvestYear: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapUiFlags([
      'dashboard.hideCompanyCultivationStatistics',
      'dashboard.hideCompanyFieldStatistics',
      'dashboard.hideCompanySoilStatistics',
      'dashboard.hideCompanySoilQualityStatistics'
    ]),
    showCompanyCultivationStatistics: {
      get () { return !this.hideCompanyCultivationStatistics },
      set (value) { this.hideCompanyCultivationStatistics = !value }
    },
    showCompanyFieldStatistics: {
      get () { return !this.hideCompanyFieldStatistics },
      set (value) { this.hideCompanyFieldStatistics = !value }
    },
    showCompanySoilStatistics: {
      get () { return !this.hideCompanySoilStatistics },
      set (value) { this.hideCompanySoilStatistics = !value }
    },
    showCompanySoilQualityStatistics: {
      get () { return !this.hideCompanySoilQualityStatistics },
      set (value) { this.hideCompanySoilQualityStatistics = !value }
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics-collapse-panel {
  margin-bottom: 4px;

  .statistics {
    margin: 1em .4em;
  }
}
</style>
