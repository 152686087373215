<template>
  <div :class="['field-overview-component', {loading: !statistics}]">
    <div v-if="statistics">
      <BsCheckbox v-model="showHints" class="checkbox" inline>
        <IxRes>frs.orgUnit.statistics.field.hints.showHints</IxRes>
      </BsCheckbox>
      <table v-for="(key, index) in Object.keys(statistics)" :key="index">
        <tr>
          <th scope="row" title="">
            <IxRes>frs.orgUnit.statistics.field.{{ key }}</IxRes>
          </th>
          <template v-if="key === 'numberFields'">
            <td>{{ printNumber(key) }}</td>
            <td />
          </template>
          <template v-else>
            <td>{{ printArea(key) }}</td>
            <td>{{ printPercentage(key) }}</td>
          </template>
        </tr>
        <tr v-if="infos.includes(key) && showHints">
          <td colspan="3" class="help">
            <HelpBox v-if="infos.includes(key) && showHints">
              <IxRes>frs.orgUnit.statistics.field.hints.{{ key }}</IxRes>
            </HelpBox>
          </td>
        </tr>
      </table>
    </div>
    <FrsLoadingIndicator :requests="['orgUnit.statistics.field']" />
  </div>
</template>

<script>
import {smarterGet} from '@helpers/vuex/data-loading'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
import HelpBox from '@components/help/HelpBox'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    FrsLoadingIndicator,
    HelpBox,
    BsCheckbox
  },
  props: {
    orgUnitId: {
      type: String,
      required: true
    },
    harvestYear: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      statistics: null,
      infos: [
        'averageArea',
        'officialArea',
        'cultivatedArea'
      ],
      showHints: false
    }
  },
  methods: {
    printNumber (value) {
      return this.statistics[value]
    },
    printArea (value) {
      return this.$i18n.format(this.statistics[value], 'hectare')
    },
    printPercentage (value) {
      return `(${this.calculateAndFormatPercentage(this.statistics[value])}%)`
    },
    calculateAndFormatPercentage (value) {
      return this.$i18n.format(value / this.statistics.geometricalArea * 100, 'number')
    }
  },
  created () {
    smarterGet('/api/v2/{harvestYear}/org/{orgUnitId}/statistics/field', {
      id: 'orgUnit.statistics.field',
      inputs: {
        harvestYear: () => this.harvestYear,
        orgUnitId: () => this.orgUnitId
      },
      onResult: statistics => {
        this.statistics = statistics
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@frs/styles/TableStyle.scss';

.field-overview-component {
  @include table-style-component;
}
</style>
