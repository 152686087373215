<template>
  <div :class="['soil-overview-statistics', {loading: !statistics}]">
    <template v-if="statistics">
      <table v-for="(data, dKey) in tableData" :key="dKey">
        <caption><IxRes>{{ `Areas.FieldRecordSystem.SR_FieldRecordSystem.${ dKey }` }}</IxRes></caption>
        <tr v-for="(row, rKey) in data" :key="rKey">
          <th scope="row">
            <IxRes>{{ `Areas.FieldRecordSystem.SR_FieldRecordSystem.${ rKey }` }}</IxRes>
          </th>
          <td>{{ printArea(row) }}</td>
          <td>{{ printPercentage(row) }}</td>
        </tr>
      </table>
    </template>
    <FrsLoadingIndicator :requests="['orgUnit.statistics.soil']" />
  </div>
</template>

<script>
import {smarterGet} from '@helpers/vuex/data-loading'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    FrsLoadingIndicator
  },
  props: {
    orgUnitId: {
      type: String,
      required: true
    },
    harvestYear: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      statistics: null,
      tableData: {
        TypeOfUse: {
          FieldUsage_arable: 'arable',
          FieldUsage_grass: 'grass',
          FieldUsage_plantation: 'plantation',
          FieldUsage_conservation: 'conservation'
        },
        WaterProtectionZone: {
          NoZonesDeposited: 'waterProtectionZone_0',
          WaterProtectionZone_1: 'waterProtectionZone_1',
          WaterProtectionZone_2: 'waterProtectionZone_2',
          WaterProtectionZone_3: 'waterProtectionZone_3',
          Natura2000: 'natura2000'
        },
        ErosionHazardZone: {
          NoHazardsDeposited: 'erosionHazardZone_0',
          ErosionHazardZone_1: 'erosionHazardZone_1',
          ErosionHazardZone_2: 'erosionHazardZone_2',
          WindErosion: 'windErosion'
        }
      }
    }
  },
  methods: {
    printArea (value) {
      return this.$i18n.format(this.statistics[value], 'hectare')
    },
    printPercentage (value) {
      return `(${this.calculateAndFormatPercentage(this.statistics[value])}%)`
    },
    calculateAndFormatPercentage (value) {
      return this.$i18n.format(value / this.statistics.geometricalFieldArea * 100, 'number')
    }
  },
  created () {
    smarterGet('/api/v2/{harvestYear}/org/{orgUnitId}/statistics/soil', {
      id: 'orgUnit.statistics.soil',
      inputs: {
        harvestYear: () => this.harvestYear,
        orgUnitId: () => this.orgUnitId
      },
      onResult: statistics => {
        this.statistics = statistics
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@frs/styles/TableStyle.scss';

.soil-overview-statistics {
  @include table-style-component;
}
</style>
