<template lang="html">
  <GeometryImportModal v-model="modelProxy" @load="onLoad">
    <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ContinueToPreview</IxRes>
  </GeometryImportModal>
</template>

<script>
import {mapActions} from 'vuex'

import {notifications} from 'src/js/infrastructure'
import {modelProxy, mapResources} from '@helpers/vuex'

import GeometryImportModal from '@components/forms/GeometryImportModal'

export default {
  components: {
    GeometryImportModal
  },
  props: {
    value: Boolean
  },
  computed: {
    modelProxy,
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/entityImport', [
      'startImportPreview'
    ]),
    onLoad (geojson) {
      this.startImportPreview(geojson)
        .catch(error => {
          console.error(error)
          notifications.error(this.SR_FieldRecordSystem.EntityImportConversionError)
        })
        .then(() => {
          this.$emit('input', false)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  position: relative;
}
</style>
