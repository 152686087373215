<template>
  <div :class="['soil-condition-component', {loading: !statistics}]">
    <SimpleTable
      v-if="statistics"
      :columns="columns" :data="tableData"
      localize-headers
    >
      <template #index="{row}">
        <IxRes>frs.orgUnit.statistics.soil_condition.range_{{ row.index }}</IxRes>
      </template>
      <template #count="{row}">
        {{ row.count }}
      </template>
      <template #areaSquareMeters="{row}">
        {{ $i18n.format(row.areaSquareMeters, 'hectare') }}
      </template>
    </SimpleTable>
    <FrsLoadingIndicator :requests="['orgUnit.statistics.soilCondition']" />
  </div>
</template>

<script>
import {mapResources} from '@helpers/vuex'
import {smarterGet} from '@helpers/vuex/data-loading'

import SimpleTable from '@components/table/SimpleTable'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    SimpleTable,
    FrsLoadingIndicator
  },
  props: {
    orgUnitId: {
      type: String,
      required: true
    },
    harvestYear: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      statistics: null
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    columns () {
      return {
        index: {title: 'frs.orgUnit.statistics.soil_condition'},
        count: {title: 'frs.orgUnit.statistics.count'},
        areaSquareMeters: {title: 'frs.orgUnit.statistics.area'}
      }
    },
    tableData () {
      return this.statistics.soilQuality.map((x, index) => ({index, ...x}))
    }
  },
  created () {
    smarterGet('/api/v2/{harvestYear}/org/{orgUnitId}/statistics/soil-condition', {
      id: 'orgUnit.statistics.soilCondition',
      inputs: {
        harvestYear: () => this.harvestYear,
        orgUnitId: () => this.orgUnitId
      },
      onResult: statistics => {
        this.statistics = statistics
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.soil-condition-component {
  position: relative;

  &.loading {
    min-height: 200px;
  }
}
</style>
