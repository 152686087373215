<template lang="html">
  <div v-if="orgUnit" style="position: relative;">
    <div class="company-header">
      <h1 :title="orgUnit.name">
        {{ orgUnit.name }}
      </h1>
      <template v-if="!orgUnit.visibleOnly">
        <div class="button-grid">
          <IxButton edit @click="openCompanyData">
            <IxRes>frs.companyDashboard.buttons.EditCompanyDetails</IxRes>
          </IxButton>
          <IxButton icon="trash" @click="deleteModalVisible = true">
            <IxRes>frs.companyDashboard.buttons.deleteCompany</IxRes>
          </IxButton>
        </div>
      </template>
    </div>

    <br>

    <div v-if="orgUnit.visibleOnly" class="alert alert-info">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.OrgUnitForbiddenHint</IxRes>
    </div>
    <template v-else>
      <div>
        <CompanyStatistics v-if="orgUnitId" :org-unit-id="orgUnitId" :harvest-year="harvestYear" />
        <IxCollapsePanel class="company-data-functions" border>
          <template #title>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FunctionsAndModules</IxRes>
          </template>
          <template #expand-text>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ExpandFunctions</IxRes>
          </template>
          <template #collapse-text>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CollapseFunctions</IxRes>
          </template>
          <OrgUnitFunctionButtons />
        </IxCollapsePanel>

        <BsModal v-model="editModalVisible">
          <template #title>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EditOrgUnit</IxRes>
          </template>
          <CompanyDataEditForm :value="orgUnit" :has-master-data="hasMasterData" />
        </BsModal>

        <ConfirmationModal
          v-model="deleteModalVisible" border
          @confirm="confirmDelete" @cancel="refuseDelete"
        >
          <template #title>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteCompany</IxRes>
          </template>
          <p>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteCompanyQuestion</IxRes>
          </p>
          <div><b> {{ orgUnit.name }}</b></div>
        </ConfirmationModal>
      </div>
    </template>
    <FrsLoadingIndicator :loading="loading" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import BsModal from '@components/bootstrap/BsModal'
import ConfirmationModal from '@components/modals/ConfirmationModal'
import IxCollapsePanel from '@components/ix/IxCollapsePanel'
import IxButton from '@components/IxButton'

import CompanyDataEditForm from './details/CompanyDataEditForm'
import OrgUnitFunctionButtons from './details/OrgUnitFunctionButtons'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import PermissionMixin from '@mixins/PermissionMixin'
import CompanyStatistics from '@frs/components/orgunit/details/CompanyStatistics'

export default {
  components: {
    CompanyStatistics,
    BsModal,
    CompanyDataEditForm,
    OrgUnitFunctionButtons,
    ConfirmationModal,
    IxCollapsePanel,
    IxButton,
    FrsLoadingIndicator
  },
  mixins: [
    PermissionMixin
  ],
  data () {
    return {
      editModalVisible: false,
      deleteModalVisible: false,
      loading: false
    }
  },
  computed: {
    ...mapResources([
      'Messages.State.SR_StateMessages'
    ]),
    ...mapState('fieldRecordSystem', {
      orgUnitDataLookup: state => state.data.orgUnits.data
    }),
    ...mapGetters('fieldRecordSystem', [
      'harvestYear',
      'orgUnitId'
    ]),
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup',
      'parentLookup'
    ]),
    orgUnit () {
      return this.entityLookup[this.orgUnitId]
    },
    hasCompanyData () {
      if (!this.orgUnitData) {
        return false
      }
      if (!this.orgUnitData.companyData) {
        return false
      }
      return true
    },
    hasCompanyNumbers () {
      if (!this.orgUnitData) {
        return false
      }
      if (!this.orgUnitData.companyNumbers) {
        return false
      }
      return true
    },
    hasMasterData () {
      const hasMasterData = this.hasCompanyData && this.hasCompanyNumbers

      return hasMasterData
    },
    orgUnitData () {
      return this.orgUnitDataLookup[this.orgUnitId] || {}
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'deleteOrgUnit'
    ]),
    ...mapActions('fieldRecordSystem/companyData', [
      'startEditing'
    ]),
    confirmDelete () {
      this.loading = true

      const parentId = this.parentLookup[this.orgUnitId]
      this.deleteOrgUnit()
        .then(() => {
          if (parentId) {
            this.$router.push({name: 'orgDashboard', params: {orgUnitId: parentId}})
          } else {
            this.$router.push({name: 'home'})
          }

          notifications.success(this.SR_StateMessages.DeleteSuccess)
        })
        .catch(error => {
          notifications.error(this.SR_StateMessages.DeleteError)
          console.error(error)
        })
        .then(() => {
          this.loading = false
        })
    },
    refuseDelete () {
      this.deleteModalVisible = false
    },
    openCompanyData () {
      this.startEditing(this.orgUnitId)
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {

}
</style>

<style lang="scss" scoped>
.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.25em;

  button {
    margin-top: 4px;
    margin-right: 4px;
    text-align: left;
  }
}
.company-header {
  color: #b9b9b9;

  h1 {
    margin-top: 0px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.company-data-functions {
  margin-top: 5px;
}

</style>
