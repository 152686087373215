<template lang="html">
  <div>
    <!-- <div class="row">
      <div class="col-md-12">
        <button type="button" @click.prevent="deleteOrgUnit" class="btn btn-danger pull-right">
          <IxRes v-if=hasMasterData>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteCompany</IxRes>
          <IxRes v-else>Areas.FieldRecordSystem.SR_FieldRecordSystem.DeleteOrgUnit</IxRes>
        </button>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-md-12">
        <label for="name"><IxRes :resource="SR_Common">Name</IxRes></label>
        <input type="text" class="form-control" id="name" v-model="data.name">
      </div>
    </div> -->
    <div class="row" style="margin-top: 15px;">
      <div class="col-md-12">
        <BoolSwitch v-model="checked">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CompanyData</IxRes>
        </BoolSwitch>
      </div>
    </div>
    <div class="row" style="margin-top: 10px;">
      <div class="col-md-12">
        <input
          type="button" class="btn btn-warning pull-right"
          :value="SR_Common.Cancel"
          @click.prevent="cancelModal"
        >
        <input
          type="button" class="btn btn-success pull-right"
          style="margin-right: 5px;"
          :value="SR_Common.Save" @click.prevent="saveModal"
        >
      </div>
    </div>
  </div>
</template>

<script>
import {mapResources} from '@helpers/vuex'

import BoolSwitch from '@components/forms/BoolSwitch'

export default {
  components: {
    BoolSwitch
  },
  props: [
    'value',
    'hasMasterData'
  ],
  data () {
    return {
      data: {
        name: null,
        id: null
      },
      checked: false
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Common.SR_Common'
    ])
  },
  methods: {
    cancelModal () {
      this.$emit('cancel')
      this.checked = this.hasMasterData
    },
    deleteOrgUnit () {
      this.$emit('delete')
    },
    saveModal () {
      const {id, name} = this.data
      this.$emit('save', {id, name, checked: this.checked})
    }
  },
  watch: {
    value: {
      handler (value) {
        const {name, id} = value || {}
        this.data = {name, id}
      },
      immediate: true
    },
    hasMasterData () {
      this.checked = this.hasMasterData
    }
  },
  mounted () {
    this.checked = this.hasMasterData
  }
}

</script>

<style lang="scss" scoped>
</style>
