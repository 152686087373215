<template>
  <div :class="[border ? 'panel-border' : '']">
    <div class="collapse-panel">
      <div class="panel-header">
        <div class="panel-header-title">
          <div class="header">
            <h4 v-if="$slots.title">
              <i v-if="icon" class="fa" :class="[`fa-${icon}`]" />
              <slot name="title" />
            </h4>
            <div class="additional-info">
              <slot name="additional-info" />
            </div>
          </div>
        </div>
        <div class="panel-header-collapse" @click.prevent.stop="collapsed = !collapsed">
          <a class="collapse-text" href="#">
            <slot v-if="collapsed" name="expand-text">
              <IxRes>Common.SR_Common.Expand</IxRes>
            </slot>
            <slot v-else name="collapse-text">
              <IxRes>Common.SR_Common.CollapseShort</IxRes>
            </slot>
          </a>
          <i class="fa fa-angle-right rotating" :class="{'rotating-out': collapsed, 'rotating-in': !collapsed}" aria-hidden="true" />
        </div>
      </div>
    </div>
    <div class="panel-content">
      <transition name="fade">
        <div v-show="!collapsed" class="collapse-panel-body">
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: Boolean,
    icon: String,
    border: Boolean
  },
  data () {
    return {
      collapsed: false
    }
  },
  watch: {
    collapsed (value) {
      this.$emit('input', value)
    },
    value (value) {
      this.collapsed = value
    }
  },
  mounted () {
    this.collapsed = this.value
  }
}
</script>

<style lang="scss" scoped>
.collapse-panel {
  display: flex;
  flex-direction: column;
  padding: 0 0.4em 0;
  width: 100%;
}
.panel-border {
  border: 1px solid #ddd;
}

.panel-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.panel-header-collapse {
  display: flex;
  cursor: pointer;
  align-items: baseline;
  i {
    position: relative;
    top: 1px;
  }
}
.collapse-text {
  margin-right: 4px;
}
.fade-enter-active {
  animation: fade 1.2s;
}
.fade-leave-active {
  animation: fade .0s reverse;
}
a {
  text-decoration: none;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.rotating-out {
  transition: transform .3s ease-in-out;
  transform: rotateZ(180deg);
}

.rotating-in {
  transition: transform .3s ease-in-out;
  transform: rotateZ(90deg);
}

.header {
  display: flex;
  flex: 1;
}

.additional-info {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.panel-content {
  overflow-y: auto;
}
</style>
