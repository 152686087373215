<template>
  <div :class="['cultivation-statistics', {loading: !statistics}]">
    <div v-if="statistics">
      <BsCheckbox
        v-if="externShowHints === null"
        v-model="showHints"
        class="checkbox" inline
      >
        <IxRes>frs.orgUnit.statistics.field.hints.showHints</IxRes>
      </BsCheckbox>
      <table>
        <tr>
          <th scope="row">
            <IxRes>cultivation.statistics.labels.totalFieldArea</IxRes>
          </th>
          <td>
            {{ $i18n.format(statistics.companyAreaSum, 'hectare') }}
          </td>
          <td />
        </tr>
        <tr v-if="showHints">
          <td colspan="3" class="help">
            <HelpBox>
              <IxRes>cultivation.statistics.hint.totalFieldArea</IxRes>
            </HelpBox>
          </td>
        </tr>
        <tr v-for="(value, index) in ['main', 'secondaryAndCatch', 'eco']" :key="index">
          <th scope="row">
            <IxRes>cultivation.statistics.labels.cultivationType_{{ value }}</IxRes>
          </th>
          <td>{{ printArea(`${value}CultivationsAreaSum`) }}</td>
          <td>{{ printPercentage(`${value}CultivationsAreaSum`) }}</td>
        </tr>
      </table>

      <HelpBox v-if="showHints">
        <IxRes>cultivation.statistics.hint.cultivationFieldPercentage</IxRes>
      </HelpBox>
    </div>
    <FrsLoadingIndicator :requests="['orgUnit.cultivationsV2.statistics']" />
  </div>
</template>

<script>
import {smarterGet} from '@helpers/vuex/data-loading'

import HelpBox from '@components/help/HelpBox'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
export default {
  components: {
    HelpBox,
    FrsLoadingIndicator,
    BsCheckbox
  },
  props: {
    harvestYear: {
      type: Number,
      required: true
    },
    orgUnitId: {
      type: String,
      required: true
    },
    externShowHints: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      statistics: null,
      internShowHints: false
    }
  },
  computed: {
    showHints: {
      get () {
        return this.externShowHints || this.internShowHints
      },
      set (value) {
        this.internShowHints = value
      }
    }
  },
  methods: {
    printArea (value) {
      return this.$i18n.format(this.statistics[value], 'hectare')
    },
    printPercentage (value) {
      return this.$i18n.format(this.statistics[value] / this.statistics.companyAreaSum, 'percent')
    }
  },
  created () {
    smarterGet(`/api/v2/${this.harvestYear}/org/${this.orgUnitId}/cultivations/statistics`, {
      id: 'orgUnit.cultivationsV2.statistics',
      onResult: (result) => {
        this.statistics = result
      }
    })
  }
}
</script>

<style lang="scss" scoped>

@import '@frs/styles/TableStyle.scss';

.cultivation-statistics {
  @include table-style-component;
}

</style>
