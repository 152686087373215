<template lang="html">
  <div class="org-unit-functions">
    <div v-if="isCultivationsAccessGranted || (isActionPortalFeature && isActionAccessGranted)">
      <h4>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CompanyActivities</IxRes>
      </h4>
      <div class="button-grid">
        <template v-if="isCultivationsAccessGranted">
          <IxButton @click="$router.push({name: 'cultivations'})">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationPlan</IxRes>
          </IxButton>
          <IxButton icon="plus-square" @click="cultivationPlanningOrgUnit({cultivationId: null})">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationPlanning</IxRes>
          </IxButton>
        </template>
        <template v-if="isActionPortalFeature && isActionAccessGranted">
          <IxButton @click="showActionOverview">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Actions</IxRes>
          </IxButton>
          <IxButton icon="plus-square" @click="startEditingAction({navigationSource: {saveTarget: 'actionOverview', cancelTarget: 'default'}})">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CreateAction</IxRes>
          </IxButton>
        </template>
      </div>
    </div>

    <div v-if="isCultivationsAccessGranted || isBasicFertilizationGranted">
      <h4>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CompanyPlanning</IxRes>
      </h4>
      <div class="button-grid">
        <IxButton v-if="isCultivationsAccessGranted" @click="showCropRotation">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CropRotations</IxRes>
        </IxButton>

        <IxButton v-if="isBasicFertilizationGranted" class="button-1-col" @click="startBasicFertilization">
          <IxRes>frs.orgUnit.button.basicFertilization</IxRes>
        </IxButton>
      </div>
    </div>

    <h4>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CompanyAdminstration</IxRes>
    </h4>

    <div class="button-grid">
      <IxButton v-if="!orgUnit.visibleOnly" :icon="icons.map" @click="loadMapManagement">
        <IxRes>frs.mapManagement.buttons.launchMapManagement</IxRes>
      </IxButton>

      <IxButton class="button-1-col" @click="showPendingZoneMapOrders">
        <IxRes>frs.orgUnit.button.pendingZonemapOrders</IxRes>
      </IxButton>

      <IxButton class="button-1-col" @click="showEntityImportModal = true">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EntityImportOrgButtonText</IxRes>
      </IxButton>

      <IxButton class="button-1-col" @click="loadProductManagement">
        <IxRes>frs.orgUnit.button.launchProductManagement</IxRes>
      </IxButton>

      <IxButton v-if="isGeoTraxGranted" class="button-1-col" @click="showCompanyLanePlans">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ManageLanePlans</IxRes>
      </IxButton>

      <template v-if="isActionAccessGranted">
        <IxButton class="button-1-col" @click="showMachinesOverview">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Machines</IxRes>
        </IxButton>

        <IxButton class="button-1-col" @click="showPersonsOverview">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Personnel</IxRes>
        </IxButton>
      </template>
    </div>

    <template v-if="orgUnitData.companyData">
      <h4>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.MasterData</IxRes>
      </h4>
      <div class="button-grid">
        <IxButton v-if="orgUnitData.companyNumbers" class="button-1-col" @click="showCompanyNumbers">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CompanyNumbers</IxRes>
        </IxButton>
      </div>
    </template>

    <br>
    <EntityImportModal v-model="showEntityImportModal" />
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

import IxButton from '@components/IxButton'
import EntityImportModal from '@frs/components/entity-import/EntityImportModal'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import PermissionMixin from '@mixins/PermissionMixin'

import {faMap} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    IxButton,
    EntityImportModal
  },
  mixins: [
    MirrorRouteMixin,
    PermissionMixin
  ],
  data () {
    return {
      showEntityImportModal: false
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.permissions
    }),
    ...mapState('fieldRecordSystem', {
      orgUnitDataLookup: state => state.data.orgUnits.data
    }),
    ...mapState('fieldRecordSystem/navigation', {
      orgUnitId: state => state.location.orgUnitId
    }),
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup'
    ]),
    orgUnit () {
      return this.entityLookup[this.orgUnitId]
    },
    orgUnitData () {
      return this.orgUnitDataLookup[this.orgUnitId] || {}
    },
    isActionAccessGranted () {
      return this.$can('Use.Frs.Actions')
    },
    isCultivationsAccessGranted () {
      return this.$can('Use.Frs.Cultivations')
    },
    isActionPortalFeature () {
      return this.$can('feature/module-actions')
    },
    isBasicFertilizationGranted () {
      return this.$can('Use.Frs.BaseFertilization')
    },
    isGeoTraxGranted () {
      return this.$can('Use.Geotrax')
    },
    icons () {
      return {
        map: faMap
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'showCultivationPlan',
      'cultivationPlanningOrgUnit',
      'showActionOverview',
      'showCropRotation',
      'showMachinesOverview',
      'showPersonsOverview',
      'showCompanyNumbers',
      'showCompanyLanePlans',
      'showDefaultYield'
    ]),
    ...mapActions('fieldRecordSystem/action', [
      'startEditingAction'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization', [
      'startBasicFertilization'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    loadMapManagement () {
      this.$router.push({name: this.createParallelRoute('mapManagement')})
    },
    loadProductManagement () {
      this.$router.push({name: 'product-management'})
    },
    showPendingZoneMapOrders () {
      this.$router.push({name: 'pendingZoneMapOrders'})
    }
  }
}
</script>

<style lang="scss" scoped>
.org-unit-functions {
  padding-left: 0.4em;
}
button {
  margin-top: 4px;
  margin-right: 4px;
  text-align: left;
}
.button-1-col {
  grid-column-start: 1;
}
.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.25em;
}
.beta-button-grid {
  grid-column-end: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: -.25em;
  padding-top: 0;
}
</style>
